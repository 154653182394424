import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
} from "@chakra-ui/react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import qs from "query-string"
import React, { FormEvent, useEffect, useState } from "react"
import {
  Authenticated,
  useAuthenticated,
} from "../../components/auth/Authenticated"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"

function ReauthenticateForm({ onSuccess }: { onSuccess: () => void }) {
  const mixpanel = useMixpanel()
  const { app, user } = useAuthenticated()

  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      if (!user.email) throw new Error("Email is not defined")
      await app.auth().signInWithEmailAndPassword(user.email, password)
      onSuccess()
    } catch (err) {
      setPassword("")

      if (err.code === "auth/wrong-password")
        setError("Wrong password, try again.")
      else {
        setError(err.message)
      }
    }

    setLoading(false)
  }

  if (!user.email) {
    throw new Error("Email is not defined")
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl id="email" display="none">
        <FormLabel>Email</FormLabel>
        <Input value={user.email} type="email" size="lg" readOnly disabled />
      </FormControl>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {/* Hide the email field so password managers can find it and know which password to use */}
        <FormControl id="password" autoComplete="current-password">
          <FormLabel>Password</FormLabel>
          <Input
            autoFocus
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            size="lg"
          />
          <small>
            <Link
              as={GatsbyLink}
              onClick={() => mixpanel.track("Click forgot password")}
              to={`/account/forgot-password?email=${encodeURIComponent(
                user.email
              )}`}
              size="sm"
              color="gray.500"
              textAlign="right"
            >
              Forgot your password?
            </Link>
          </small>
        </FormControl>
        <Button type="submit" colorScheme="blue" size="lg" isLoading={loading}>
          Continue
        </Button>
      </FormVStack>
    </form>
  )
}

function parseToUrlFromWindowLocation() {
  const { to } = qs.parse(window.location.search)
  return to
}

export default function Reauthenticate() {
  const [to, setTo] = useState("")

  useEffect(() => {
    const to = parseToUrlFromWindowLocation()
    if (!to) {
      throw new Error(
        "The `to` url parameter isn't specified in the query string. This page is meant to authenticate you before taking you to another page, but there's no other page to take you to."
      )
    }
    if (typeof to !== "string") {
      throw new Error("The `to` url parameter should be a string")
    }
    setTo(to)
  }, [])

  return (
    <FormLayout title="✋ Verify It's You" showBack>
      <Authenticated>
        <ReauthenticateForm
          // Replace because we don't want this "confirmation" screen in history
          // Back button will not return to this page
          onSuccess={() => navigate(to, { replace: true })}
        />
      </Authenticated>
    </FormLayout>
  )
}
